import * as React from "react";
import { graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Layout from "../components/layout.js";
import SEO from "../components/seo.js";

const Gallery = ({ data }) => {
  const shuffle = (arr) => [...arr].sort(() => Math.random() - 0.5);
  const imageData = shuffle(data.allFile.edges);

  const returnCaption = (data) => {
    const arr = data.split(":");
    return (
      <div className="overlay">
        <div className="caption">
          <h3>{arr[0]}</h3>
          <p>{arr[1]}</p>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <SEO title="Gallery" />
      <main className="gallery">
        {imageData.map((photo) => (
          <BackgroundImage
            key={photo.node.id}
            Tag="div"
            className="gallery-image"
            fluid={photo.node.childImageSharp.fluid}
            backgroundColor={`#000000`}
          >
            {returnCaption(photo.node.childImageSharp.fields.exif.raw.image.ImageDescription)}
          </BackgroundImage>
        ))}
      </main>
    </Layout>
  );
};

export const query = graphql`
  query getPhotos {
    allFile(filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" }, relativeDirectory: { eq: "photos" } }, sort: { fields: base, order: ASC }) {
      edges {
        node {
          id
          base
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
            fields {
              exif {
                raw {
                  image {
                    ImageDescription
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Gallery;
